<template>
  <v-container fluid>
    <v-row no-gutters>
      <!-- Main Card -->
      <v-col cols="12" v-if="isLoaded" class="mt-6">
        <v-card class="mx-2">
          <v-row no-gutters>
            <v-col cols="12" class="mt-2">
              <v-row justify="space-between" no-gutters>
                <div class="mx-2">
                  <h1>{{ item.name }}</h1>
                </div>
                <div class="mx-2">
                  <!-- Type -->
                  <v-chip>{{ item.type }}</v-chip>

                  <v-btn icon @click="setShowMainEditDialog(true)">
                    <v-icon>fas fa-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="setShowDeleteDialog(true)">
                    <v-icon>far fa-trash</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </v-col>

            <!-- Suppliers -->
            <v-col cols="12">
              <span>{{ $t("components.labels.suppliers") }} :</span>
              <template v-for="supplier of item.suppliers">
                <SupplierLink :key="supplier.id" :supplier="supplier" />
              </template>
            </v-col>

            <!-- FM Referents -->
            <v-col cols="12">
              <span>{{ $t("components.labels.fm_referents") }} :</span>
              <template v-for="fm_referent of item.fm_referents">
                <FMReferent
                  v-if="fm_referent"
                  :key="fm_referent.id"
                  :item="fm_referent"
                />
              </template>
            </v-col>

            <!-- Public name -->
            <v-col cols="12">
              <span>{{ $t("components.labels.publicName") }} :</span>
              <span>{{ item.publicName }}</span>
            </v-col>

            <!-- Weight -->
            <v-col cols="12">
              <span>{{ $t("components.labels.weight") }} :</span>
              <span>{{ item.weight }}</span>
            </v-col>

            <!-- Traceability grade -->
            <v-col cols="12">
              <span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="pointer" v-bind="attrs" v-on="on">
                      {{ $t("components.labels.traceability_grade") }} :
                    </span>
                  </template>
                  <span class="font-weight-bold">
                    {{ item.traceability_grade }} / 5
                  </span>
                </v-tooltip>
              </span>
              <v-rating
                v-model="item.traceability_grade"
                background-color="purple lighten-3"
                readonly
                color="yellow"
                half-increments
                hover
                small
              />
            </v-col>

            <!-- Compliance grade -->
            <v-col cols="12">
              <span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="pointer" v-bind="attrs" v-on="on">
                      {{ $t("components.labels.compliance_grade") }} :
                    </span>
                  </template>
                  <span class="font-weight-bold">
                    {{ item.compliance_grade }} / 5
                  </span>
                </v-tooltip>
              </span>
              <span>
                <v-rating
                  v-model="item.compliance_grade"
                  background-color="purple lighten-3"
                  readonly
                  color="yellow"
                  half-increments
                  hover
                  small
                ></v-rating>
              </span>
            </v-col>

            <!-- Total KM -->
            <v-col cols="12" class="my-1">
              <span>{{ $t("components.labels.avgKM") }} :</span>
              <span>{{ item.avgKM }}</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Tabs card -->
      <v-col cols="12" class="mt-6">
        <v-tabs class="mx-4" v-model="tab" @change="switchTab" grow>
          <v-tab
            class="text-transform-none font-size-14"
            href="#tab-1"
            :exact="true"
          >
            {{ $t("components.composition.title") }}
          </v-tab>
          <v-tab
            class="text-transform-none font-size-14"
            href="#tab-2"
            :exact="true"
            disabled
          >
            {{ $t("components.traceability.title") }}
          </v-tab>
          <v-tab
            class="text-transform-none font-size-14"
            href="#tab-3"
            :exact="true"
          >
            {{ $t("components.products.title") }}
          </v-tab>
          <v-tab
            class="text-transform-none font-size-14"
            href="#tab-4"
            :exact="true"
          >
            {{ $t("components.material-forms.title") }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="ma-4">
          <!-- Composition -->
          <v-tab-item value="tab-1">
            <ComponentCompositionTab v-if="isLoaded" :component="item" />
            <Skeleton v-else type="card" :occurrence="1" />
          </v-tab-item>

          <!-- Traceability -->
          <v-tab-item value="tab-2">
            <ComponentTraceabilityTab v-if="isLoaded" :component="item" />
            <Skeleton v-else type="card" :occurrence="1" />
          </v-tab-item>

          <!-- Products -->
          <v-tab-item value="tab-3">
            <CommonStudiesTab v-if="isLoaded" :component="item" />
            <Skeleton v-else type="card" :occurrence="1" />
          </v-tab-item>

          <!-- Material forms -->
          <v-tab-item value="tab-4">
            <MaterialFormsTab v-if="isLoaded" :component="item" />
            <Skeleton v-else type="card" :occurrence="1" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!-- Component main dialog -->
    <ComponentMainDialog
      v-if="showDialog.main"
      :value="showDialog.main"
      :item="item"
      @close="(needRefresh) => setShowMainEditDialog(false, needRefresh)"
    />

    <!-- Component delete dialog -->
    <DeleteDialog
      v-if="showDialog.delete"
      :value="showDialog.delete"
      :title="$t('components.deleteDialog.title')"
      @submit="deleteComponent"
      @close="(needRefresh) => setShowDeleteDialog(false, needRefresh)"
    />
  </v-container>
</template>

<script>
import formatter from "@/mixins/formatter";
import tabsUtils from "@/mixins/tabs-utils";

export default {
  name: "ComponentView",

  components: {
    MaterialFormsTab: () =>
      import("@/components/Common/MaterialForms/MaterialFormsTab"),
    Skeleton: () => import("@/components/Common/Cards/Skeleton"),
    SupplierLink: () => import("@/components/Common/Cards/SupplierLink"),
    FMReferent: () => import("@/components/Common/Cards/FMReferent"),
    ComponentMainDialog: () =>
      import("@/components/Common/Components/ComponentMainDialog"),
    DeleteDialog: () => import("@/components/Common/DeleteDialog"),

    CommonStudiesTab: () =>
      import("@/components/Common/Studies/CommonStudiesTab"),
    ComponentTraceabilityTab: () =>
      import("@/components/Components/ComponentTraceabilityTab"),
    ComponentCompositionTab: () =>
      import("@/components/Components/ComponentCompositionTab"),
  },

  mixins: [formatter, tabsUtils],

  data() {
    return {
      showDialog: {
        main: false,
        delete: false,
      },

      isLoaded: false,
      isLoading: {
        btnSave: false,
      },
      item: null,
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.isLoaded = false;
      this.isLoading.btnSave = false;

      this.$http
        .get(`/components/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.item = res.data;
          this.$store.commit("pageHead/setTitle", `${this.item.name}`);
        })
        .catch((err) => {
          this.$store.commit("toast/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },

    deleteComponent() {
      this.$http
        .delete(`/components/${this.item.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("components.deleteDialog.success")
          );
          this.setShowDeleteDialog(false);
          this.$router.push({ name: "Components" });
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtnSave = false;
        });
    },

    setShowMainEditDialog(val, reloadData = false) {
      if (reloadData) this.getData();

      this.showDialog.main = val;
    },

    setShowDeleteDialog(val, reloadData = false) {
      if (reloadData) this.getData();

      this.showDialog.delete = val;
    },
  },
};
</script>

<style scoped></style>
