<template>
  <v-container fluid>
    <v-row justify="start" class="ma-2" no-gutters>
      <v-col cols="12">
        <h1>{{ $t("components.title") }}</h1>
      </v-col>

      <!-- Buttons -->
      <v-col cols="12" class="mt-6 text-right">
        <v-btn color="primary" @click="setShowAddDialog(true)">
          {{ $t("components.buttons.add") }}
        </v-btn>
      </v-col>

      <v-col cols="12" class="mt-6">
        <div>
          <v-text-field
            v-model="search"
            append-icon="fas fa-search"
            :label="$t('common.inputs.search')"
            clearable
            v-on:click:clear="clearInput"
            @keyup.enter.native="searchTrigger"
            outlined
          ></v-text-field>
        </div>
      </v-col>

      <!-- Table -->
      <v-col cols="12" class="mt-2">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :loading="loading"
          :items-per-page="20"
          style="min-height: 600px"
          sort-by="name"
          :sort-desc="false"
          :footer-props="{ 'items-per-page-options': itemsPerPage }"
          class="elevation-1"
        >
          <!-- Name -->
          <template v-slot:item.name="{ item }">
            <router-link :to="{ name: 'Component', params: { id: item.id } }">
              {{ item.name }}
            </router-link>
          </template>

          <!-- Public Name -->
          <template v-slot:item.publicName="{ item }">
            {{ item.publicName }}
          </template>

          <!-- Type -->
          <template v-slot:item.type="{ item }">
            {{ item.type }}
          </template>

          <!-- Category -->
          <template v-slot:item.category="{ item }">
            {{ item.category }}
          </template>

          <!-- Supplier -->
          <template v-slot:item.suppliers="{ item }">
            <template v-if="item.suppliers.length > 0">
              <template v-for="supplier of item.suppliers">
                <SupplierLink :key="supplier.id" :supplier="supplier" />
              </template>
            </template>
            <span v-else>{{ $t("common.misc.NA") }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Main project dialog -->
    <ComponentMainDialog
      v-if="showDialog.add"
      :value="showDialog.add"
      :item="null"
      @close="(needRefresh) => setShowAddDialog(false, needRefresh)"
    />
  </v-container>
</template>

<script>
import vuetifyDatatable from "@/mixins/vuetifyDataTable";
import formatter from "@/mixins/formatter";

export default {
  name: "ComponentsView",

  components: {
    SupplierLink: () => import("@/components/Common/Cards/SupplierLink"),
    ComponentMainDialog: () =>
      import("@/components/Common/Components/ComponentMainDialog"),
  },

  mixins: [vuetifyDatatable, formatter],

  data() {
    return {
      headers: [
        {
          text: this.$t("components.datatable.headers.name"),
          value: "name",
          sortable: false,
          width: "40%",
        },
        {
          text: this.$t("components.datatable.headers.publicName"),
          value: "publicName",
          sortable: false,
          width: "20%",
        },
        {
          text: this.$t("components.datatable.headers.type"),
          value: "type",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("components.datatable.headers.category"),
          value: "category",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("components.datatable.headers.suppliers"),
          value: "suppliers",
          sortable: false,
          width: "40%",
        },
      ],
      URL_API: {
        GET_ALL: "/components",
      },

      showDialog: {
        add: false,
      },
      isLoadingExportBtn: false,
      savePaginationURL: true,
    };
  },

  created() {
    this.$store.commit("pageHead/setTitle", this.$t("components.pageTitle"));
  },

  methods: {
    setShowAddDialog(val, reloadData = false) {
      if (reloadData) this.searchTrigger();
      this.showDialog.add = val;
    },

    goToOne(obj) {
      this.$router.push({
        name: "Component",
        params: { id: obj.id },
      });
    },
  },
};
</script>

<style scoped></style>
